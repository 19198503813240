.full-body-height {
    height: calc(100vh - 220px);
}

.print-all-badge {
    height: 20px;
    min-width: 20px;
    width: max-content;
    font-size: 12px;
    right: 0px;
    border-radius: 15px;
    transform: translate(50%, -50%);
}

.searched-label:hover {
    cursor: pointer;
    background: #eef2f7;
}

.label-print-button-1{
    position: absolute;
    top: 20px;
    right: 30px;
}

.label-print-button-2{
    position: absolute;
    top: 20px;
    left: 30px;
}

/* toastify classes */
/* figure out how to change icon colors */

:root {
    --toastify-color-info: #39afd1 !important;
    --toastify-color-success: #0acf97 !important;
    --toastify-color-warning: #ffbc00 !important;
    --toastify-color-error: #fa5c7c !important;
}

.Toastify__toast-container--top-right {
    top: 5em !important;
}

.invalid-text {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    color: #fa5c7c;
}

.password-modal-backdrop{
    opacity: 1 !important;
}

.invalid-text {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    color: #fa5c7c;
}

.small-text {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.75rem;
}