span.cls_004 {
    font-family: sans-serif;
    font-size: 13.6px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
}
div.cls_004 {
    font-family: sans-serif;
    font-size: 13.6px;
    color: rgb(0, 0, 0);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
}
span.cls_003 {
    font-family: Arial, serif;
    font-size: 7.9px;
    color: rgb(0, 0, 0);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
}
div.cls_003 {
    font-family: Arial, serif;
    font-size: 7.9px;
    color: rgb(0, 0, 0);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
}
span.cls_002 {
    font-family: Arial, serif;
    font-size: 15.9px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
}
div.cls_002 {
    font-family: Arial, serif;
    font-size: 15.9px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
}
span.cls_005 {
    font-family: Arial, serif;
    font-size: 17.6px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
}
div.cls_005 {
    font-family: Arial, serif;
    font-size: 17.6px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
}
span.cls_007 {
    font-family: Arial, serif;
    font-size: 10.7px;
    color: rgb(0, 0, 0);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
}
div.cls_007 {
    font-family: Arial, serif;
    font-size: 10.7px;
    color: rgb(0, 0, 0);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
}
span.cls_006 {
    font-family: Arial, serif;
    font-size: 12px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
}
div.cls_006 {
    font-family: Arial, serif;
    font-size: 12px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
}
span.cls_008 {
    font-family: Arial, serif;
    font-size: 35.1px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
}
div.cls_008 {
    font-family: Arial, serif;
    font-size: 35.1px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
    line-height: 1;
}
span.cls_009 {
    font-family: Courier New, serif;
    font-size: 8.6px;
    color: rgb(0, 0, 0);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
}
div.cls_009 {
    font-family: Courier New, serif;
    font-size: 8.6px;
    color: rgb(0, 0, 0);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
}
span.cls_010 {
    font-family: Arial, serif;
    font-size: 10px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
}
div.cls_010 {
    font-family: Arial, serif;
    font-size: 10px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
}
span.cls_011 {
    font-family: Arial, serif;
    font-size: 12.4px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
}
div.cls_011 {
    font-family: Arial, serif;
    font-size: 12.4px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
}

span.cls_012 {
    font-family: Arial, serif;
    font-size: 27px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
}
div.cls_012 {
    font-family: Arial, serif;
    font-size: 27px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
}

.label-box-border {
    border: 4px solid black;
}

.label-hr {
    border: 1px solid black;
}
span.cls_015 {
    font-family: sans-serif;
    font-size: 16.6px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
}
div.cls_015 {
    font-family: sans-serif;
    font-size: 16.6px;
    color: rgb(0, 0, 0);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
}
